import { Error as ErrorIcon } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, Grid, Link, MenuItem, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { useIntl } from 'gatsby-plugin-react-intl';
import React, { memo, useState } from 'react';
import * as yup from 'yup';
import mobileNumberPrefixes from '../../constants/mobileNumberPrefixes';
import { callApi, ErrorResponse } from '../../helpers/api';
import localeData from '../../helpers/locale-data';
import { storeSession } from '../../helpers/session';
import { IStep1, IStepProps } from '../../types/step';
import InputField from '../InputField';
import { Form, FormActions, Header, StepContainer } from './components';

const PrivacyPolicy = ({ formik }: { formik: ReturnType<typeof useFormik<IStep1>> }) => {
  const intl = useIntl();

  return (
    <FormControlLabel
      control={<Checkbox required name="agreedToPP" checked={formik.values.agreedToPP} onChange={formik.handleChange} />}
      label={
        <Typography>
          I have read and consent to the collection, use, holding and disclosure of my information set out in the&nbsp;
          <Link
            target="_blank"
            rel="noreferrer"
            href={intl.formatMessage({
              description: 'Privacy Policy URL',
              defaultMessage: 'https://presspay.com.au/privacy-policy-202309/',
            })}
          >
            Privacy Policy
          </Link>
          .
        </Typography>
      }
    />
  );
};

const StepStart = ({ step, onNextClick }: IStepProps) => {
  const [error, setError] = useState('');
  const intl = useIntl();
  const locale = intl.locale as keyof typeof localeData;
  const mobileRegExp = localeData[locale]?.mobileRegExp || /^(\d+)$/;

  const countryCodeInvalidError = intl.formatMessage({
    defaultMessage: 'Please select a valid country code.',
    description: 'Country code invalid error',
  });
  const emailExistsError = intl.formatMessage({
    defaultMessage: 'It looks like you have already signed up with this email address.',
    description: 'Email already exists error',
  });
  const emailInvalidError = intl.formatMessage({
    defaultMessage: 'Please enter a valid email address.',
    description: 'Email invalid error',
  });
  const mobileNumberExistsError = intl.formatMessage({
    defaultMessage: 'It looks like you have already signed up with this mobile number.',
    description: 'Mobile number already exists error',
  });
  const mobileNumberInvalidError = intl.formatMessage({
    defaultMessage: 'Please enter a valid mobile number.',
    description: 'Mobile number invalid error',
  });
  const mobileNumberVerificationError = intl.formatMessage({
    defaultMessage: 'It looks like we are having trouble verifying your mobile number. Please check the number and try again.',
    description: 'Mobile number verification error',
  });

  const validationSchema = yup.object({
    email: yup.string().email(emailInvalidError),
    mobileNumber: yup.string().required(mobileNumberInvalidError).matches(mobileRegExp, mobileNumberInvalidError),
    mobileNumberPrefix: yup
      .string()
      .required(countryCodeInvalidError)
      .oneOf(
        mobileNumberPrefixes.map((x) => x.value),
        countryCodeInvalidError,
      ),
  });

  const formik = useFormik<IStep1>({
    initialValues: step as IStep1,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setError('');

      try {
        const { email, mobileNumber, mobileNumberPrefix } = values;
        const res = await callApi('/sessions', 'post', {
          data: {
            attributes: {
              email,
              locale,
              mobileNumber: mobileNumber.replace(mobileRegExp, (_, a: string) => [mobileNumberPrefix, a].join('')),
            },
          },
        });
        storeSession(res.data.data);
        onNextClick && onNextClick(values);
      } catch (err) {
        setErrors(err as Error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const setErrors = (err: Error) => {
    try {
      if (axios.isAxiosError(err)) {
        const errors: ErrorResponse[] = err.response?.data.errors;
        for (const error of errors) {
          const { key, label } = error.meta || {};

          if (key === 'email') {
            formik.setFieldError(key, label === 'EMAIL_EXISTS' ? emailExistsError : emailInvalidError);
          } else if (key === 'mobileNumber') {
            formik.setFieldError(key, label === 'MOBILE_NUMBER_EXISTS' ? mobileNumberExistsError : mobileNumberInvalidError);
          } else if (key === 'mobileNumberVerification') {
            setError(mobileNumberVerificationError);
          } else {
            throw err;
          }
        }
      } else {
        throw err;
      }
    } catch {
      // Set a generic error message because we cannot get field specific errors
      setError(
        intl.formatMessage({
          defaultMessage: 'There was an error submitting your details, please try again later.',
          description: 'Generic email/mobile number error',
        }),
      );
    }
  };

  return (
    <StepContainer>
      <Header>Easy, early access for up to $1,000 of your regular income every pay cycle</Header>
      <ol>
        <li>You must be over 18 years old and earn a regular income.</li>
        <li>You'll need your drivers licence, medicare card or Australian passport to verify your identity.</li>
        <li>We'll need to verify your income.</li>
      </ol>
      <Typography gutterBottom>Lets get started.</Typography>
      <Form onReset={formik.handleReset} onSubmit={formik.handleSubmit} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Stack spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={4} sm={2}>
              <InputField
                fullWidth
                formik={formik}
                name="mobileNumberPrefix"
                select
                SelectProps={{ renderValue: (x) => x }}
                variant="outlined"
              >
                {mobileNumberPrefixes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            </Grid>
            <Grid item xs={8} sm={10}>
              <InputField fullWidth required autoFocus type="tel" name="mobileNumber" label="Mobile number" formik={formik} />
            </Grid>
          </Grid>
          <InputField fullWidth required name="email" label="Email" formik={formik} />
          <PrivacyPolicy formik={formik} />
          {error && (
            <Stack direction="row" spacing={1}>
              <ErrorIcon color="error" />
              <Typography color="error">{error}</Typography>
            </Stack>
          )}
        </Stack>

        <FormActions justifyContent="flex-start">
          <Button disabled={formik.isSubmitting} type="submit" variant="contained">
            {intl.formatMessage({ defaultMessage: 'Register now', description: 'Register button' })}
          </Button>
        </FormActions>
      </Form>
    </StepContainer>
  );
};

export default memo(StepStart);
